const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备",
                prop: "SBMC",
                minWidth: "180"
            }, {
                label: "时间",
                prop: "JCSJ",
                minWidth: "180"
            }, {
                label: "ph值",
                prop: "PH",
                minWidth: "110"
            }, {
                label: "电导率(S/m)",
                prop: "DDL",
                minWidth: "110"
            }, {
                label: "溶解氧(mg/L)",
                prop: "RJY",
                minWidth: "110"
            }, {
                label: "盐度(%)",
                prop: "YD",
                minWidth: "110"
            }, {
                label: "溶解质(tds)",
                prop: "RJZ",
                minWidth: "110"
            }, {
                label: "海水比重",
                prop: "HSBZ",
                minWidth: "110"
            }, {
                label: "温度(℃)",
                prop: "WD",
                minWidth: "110"
            }, {
                label: "浊度(NTU)",
                prop: "ZD",
                minWidth: "110"
            }, {
                label: "深度(m)",
                prop: "SD",
                minWidth: "110"
            }, {
                label: "氧化还原电位(orp)",
                prop: "YHHYDW",
                minWidth: "140"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
            //     isShow: true,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };